import { registerApplication, start } from "single-spa";
import { BehaviorSubject } from "rxjs";
import GetToken from "../packages/apps/ala-admin/src/authService/GetToken";
import { createSubjectAndObservable } from "./util/createSubjectAndObservable";

const myVal = window.APP_ENV;
if (myVal === undefined) {
  window.APP_ENV = {
    adminApiUrl: process.env.REACT_APP_MPNG_ADMIN_API_BASE_URL,
    userApiUrl: process.env.REACT_APP_MPNG_USER_API_BASE_URL,
    lmsApiUrl: process.env.REACT_APP_MPNG_LMS_API_BASE_URL,
    lmsFlagrUrl: process.env.REACT_APP_MPNG_FLAGR_API_BASE_URL,
    authority: process.env.REACT_APP_IMLP_AUTHORITY,
    client_id: process.env.REACT_APP_IMLP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_IMLP_REDIRECT_URI,
    post_logout_redirect_uri: process.env.REACT_APP_IMLP_POST_LOGOUT_REDIRECT_URI,
    response_type: process.env.REACT_APP_IMLP_RESPONSE_TYPE,
    scope: process.env.REACT_APP_IMLP_SCOPE,
    tenant: process.env.REACT_APP_IMLP_TENANT,
    rad_api_url: process.env.REACT_APP_RAD_API_BASE_URL,
    graphql_api_url: process.env.REACT_APP_GRAPHQL_API_URL,
    flagr_base_url: process.env.REACT_APP_RAD_FLAGR_BASE_URL,
    cicGroupId: process.env.REACT_APP_CIC_GROUP_ID,
    cat_launch_url: process.env.REACT_APP_CAT_LAUNCH_URL,
    doLittleToken_Base_URL: process.env.REACT_APP_DO_LITTLE_TOKEN_BASE_URL,
    reports_graphql_url: process.env.REACT_APP_GRAPGQL_API_BASE_URL,
    SpanishLanguageMathGroupId: process.env.REACT_APP_SPANISH_LANGUAGE_MATH_GROUP_ID,
    SpanishLanguageScienceGroupId: process.env.REACT_APP_SPANISH_LANGUAGE_SCIENCE_GROUP_ID,
    catTestStatusUrl: process.env.REACT_APP_CAT_TEST_STATUS_URL,

    student_client_id: process.env.REACT_APP_STUDENT_CLIENT_ID,
    student_redirect_uri: process.env.REACT_APP_STUDENT_REDIRECT_URI,
    student_post_logout_redirect_uri: process.env.REACT_APP_STUDENT_POST_LOGOUT_REDIRECT_URI,

    settings_app_environment: process.env.REACT_APP_SETTINGS_APP_ENV,
    assignment_builder_url: process.env.REACT_APP_ASSIGNMENT_BUILDER_API_BASE_URL,
    flagr_base_url_assignment_builder: process.env.REACT_APP_FLAGR_BASE_URL_ASSIGNMENT_BUILDER,
    token_expiry_time: process.env.REACT_APP_TOKEN_EXPIRY_AFTER_MINUTES,
    refresh_token_expiry_time: process.env.REACT_APP_REFRESH_TOKEN_EXPIRY_AFTER_MINUTES,

    doolittle_api_base_url: process.env.REACT_APP_DOOLITTLE_API_BASE_URL,
    doolittle_reader_base_url: process.env.REACT_APP_DOOLITTLE_READER_BASE_URL,
    assessment_player_url: process.env.REACT_APP_FIXEDFORM_PLAYER_URL,
    assessment_player_ctk_env_url: process.env.REACT_APP_FIXEDFORM_PLAYER_CTK_ENV_URL,
    assessment_player_ctk_env_id: process.env.REACT_APP_FIXEDFORM_PLAYER_CTK_ENV_ID,
    sentry_env: process.env.REACT_APP_SENTRY_ENV,
    student_ilp_sentry_dsn: process.env.REACT_APP_STUDENT_ILP_SENTRY_DSN,
    class_summary_sentry_dsn: process.env.REACT_APP_CLASS_SUMMARY_SENTRY_DSN,
    reset_start_date: process.env.REACT_APP_RESET_START_DATE,
    reset_end_date: process.env.REACT_APP_RESET_END_DATE,
    REACT_APP_GUIDS: process.env.REACT_APP_GUIDS,
    academic_reset_start_date: process.env.REACT_APP_RESET_START_DATE,
    academic_reset_end_date: process.env.REACT_APP_RESET_END_DATE,
    dsds_report_url: process.env.REACT_APP_DSDS_APP_ENV_URL,
    mpng_api_base_url: process.env.REACT_APP_MPNG_API_BASE_URL,
    ala_authority: process.env.REACT_APP_AUTHORITY,
    ala_client_id: process.env.REACT_APP_CLIENT_ID,
    mpp_user_client_id: process.env.REACT_APP_MPP_CLIENT_ID,
    ala_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    ala_post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    ala_response_type: process.env.REACT_APP_RESPONSE_TYPE,
    ala_scope: process.env.REACT_APP_SCOPE,
    old_admin_vanity_url: process.env.REACT_APP_OLD_ADMIN_VANITY_URL,
    admin_wna_cloud_url: process.env.REACT_APP_ADMIN_WNE_CLOUD_URL,
    old_student_vanity_url: process.env.REACT_APP_OLD_STUDENT_VANITY_URL,
    student_wna_cloud_url: process.env.REACT_APP_STUDENT_WNE_CLOUD_URL,
    studentAssignmentStatusURL: process.env.REACT_APP_ALP_COUNT,
    ulpRedirectUrl: process.env.REACT_APP_ULP_BASE_URL,
    pssBaseUrl: process.env.REACT_APP_PSS_BASE_URL,
    itemEngineAuthorUri: process.env.REACT_APP_ITEM_ENGINE_AUTHOR_URI,
    itemEngineItemsUri: process.env.REACT_APP_ITEM_ENGINE_ITEMS_URI,
    domain_mapping_api_base_url: process.env.domain_mapping_api_base_url,
    data_pier_api_url: process.env.REACT_APP_DATAPIER_API_BASE_URL,
    mps_ui_assessment_url: process.env.mps_ui_assessment_url
  };
}

const [breadcrumbSubject, breadcrumbObservable] = createSubjectAndObservable<{
  crumbs: Array<object>;
}>({
  crumbs: []
});
const [tokenExpiryMessageSubject, tokenExpiryMessageSubjectObservable] = createSubjectAndObservable<string>("");
const [schedulerAssessmentSubject, schedulerAssessmentObservable] = createSubjectAndObservable<object>({});
const [ilpsubject, ilpObservable] = createSubjectAndObservable({});
const [MyPathLevelSubject, MyPathLevelSubjectObservable] = createSubjectAndObservable({
  name: "Class Name From ALA"
});
const [MyPathBreadcrumbsSubject, MyPathBreadcrumbsSubjectObservable] = createSubjectAndObservable({ crumbs: [] });
const [authTokenSubject, authTokenSubjectObservable] = createSubjectAndObservable(GetToken());
const [MyPathReassignSubject, MyPathReassignButtonObservable] = createSubjectAndObservable(true);
const [MyPathLoaderSubject, MyPathLoaderSubjectObservable] = createSubjectAndObservable(true);
const [assignmentBuilderBreadcrumbSubject, assignmentBuilderBreadcrumbObservable] = createSubjectAndObservable<object>(
  {}
);
const [assignmentBuilderCustomPropsSubject, assignmentBuilderCustomPropsSubjectObservable] =
  createSubjectAndObservable<object>({});
const [BenchmarkGrowthReportBreadcrumbSubject, BenchmarkGrowthReportBreadcrumbSubjectObservable] =
  createSubjectAndObservable({
    name: "Class Name From ALA"
  });
const [BenchmarkGrowthReportBackSubject, BenchmarkGrowthReportBackObservable] = createSubjectAndObservable(false);
const [ActivityIndicatorSubject, ActivityIndicatorObservable] = createSubjectAndObservable(false);
const [mpngClassSummarySubject, mpngClassSummaryObservable] = createSubjectAndObservable<object>({
  classId: "",
  className: "",
  subject: "",
  academicSessionId: "",
  orgTimezone: "",
  schoolYear: "",
  schoolGeoState: "",
  organizationId: ""
});
const [mpngStudentIlpSubject, mpngStudentIlpObservable] = createSubjectAndObservable<object>({
  studentId: "",
  subject: "",
  ilpId: "",
  academicSessionId: "",
  orgTimezone: "",
  firstname: "",
  lastname: "",
  schoolYear: "",
  returnroute: "",
  organizationId: "",
  referrer: {
    returnRoute: "",
    label: ""
  }
});
const [dsdsReportSubject, dsdsReportObservable] = createSubjectAndObservable({});
const [studentProgressSubject, studentProgressObservable] = createSubjectAndObservable({ studentid: "" });
const [subjectMFE, observableMFE] = createSubjectAndObservable({
  orgGuids: "",
  userGuid: "",
  siteCode: "",
  userRole: "",
  userName: ""
});
const [domainMappingSubject, domainMappingObservable] = createSubjectAndObservable<{}>({});
const [standardsProficiencyReportBreadcrumbsSubject, standardsProficiencyReportBreadcrumbsObservable] =
  createSubjectAndObservable({ crumbs: [] });

const timeZoneSubject = new BehaviorSubject<string>("");

const mypathReportActiveFlagsSubject = new BehaviorSubject<string[]>([]);
const productCode = "MyPath";

registerApplication(
  "@wne-spa/ala-admin",
  () => System.import("@wne-spa/ala-admin") as any,
  () => location.pathname.includes("/"), //mount
  {
    tokenSubject: authTokenSubject,
    breadcrumbSubject,
    breadcrumbObservable,
    tokenExpiryMessageSubjectObservable,
    schedulerAssessmentSubject,
    schedulerAssessmentObservable,
    ilpsubject,
    ilpObservable,
    MyPathLevelSubject,
    MyPathBreadcrumbsSubjectObservable,
    MyPathReassignSubject,
    MyPathReassignButtonObservable,
    assignmentBuilderBreadcrumbSubject,
    assignmentBuilderBreadcrumbObservable,
    BenchmarkGrowthReportBreadcrumbSubject,
    BenchmarkGrowthReportBreadcrumbSubjectObservable,
    BenchmarkGrowthReportBackSubject,
    BenchmarkGrowthReportBackObservable,
    standardsProficiencyReportBreadcrumbsObservable,
    ActivityIndicatorSubject,
    ActivityIndicatorObservable,
    assignmentBuilderCustomPropsSubject,
    assignmentBuilderCustomPropsSubjectObservable,
    mpngClassSummarySubject,
    mpngClassSummaryObservable,
    mpngStudentIlpSubject,
    mpngStudentIlpObservable,
    dsdsReportSubject,
    studentProgressSubject,
    subjectMFE,
    observableMFE,
    timeZoneSubject,
    MyPathLoaderSubject,
    MyPathLoaderSubjectObservable,
    mypathReportActiveFlagsSubject,
    domainMappingSubject,
    domainMappingObservable
  }
);

registerApplication(
  "@wne-spa/rad-ui-usage-reports-mf",
  () => System.import("@wne-spa/rad-ui-usage-reports-mf") as any,
  () => location.pathname.includes("/reports") || location.pathname.includes("/reports1"),
  {
    domElement: document.getElementById("reports"),
    env: {
      REACT_APP_RAD_API_BASE_URL: `${window.APP_ENV.rad_api_url}`,
      REACT_APP_FLAGR_BASE_URL: `${window.APP_ENV.flagr_base_url}`
    },
    baseUrl: System.resolve("@wne-spa/rad-ui-usage-reports-mf"),
    tokenSubject: authTokenSubject,
    breadcrumbSubject,
    breadcrumbObservable,
    tokenExpiryMessageSubject: tokenExpiryMessageSubject,
    subjectMFE,
    observableMFE,
    timeZoneSubject
  }
);

registerApplication(
  "@wne/cat-scheduler-ui",
  () => System.import("@wne/cat-scheduler-ui") as any,
  () => location.pathname.includes("/assessmentschedule"),
  {
    domElement: document.getElementById("assessmentschedule"),
    baseUrl: System.resolve("@wne/cat-scheduler-ui"),
    schedulerAssessmentSubject,
    schedulerAssessmentObservable,
    subjectMFE,
    observableMFE
  }
);

registerApplication(
  "@wne/class-progress-report",
  () => System.import("@wne/class-progress-report") as any,
  () => location.pathname.includes("/classprogress"),
  {
    domElement: document.getElementById("classprogress"),
    envJson: {
      REACT_APP_RAD_FASTLANE_API_URL: `${window.APP_ENV.reports_graphql_url}`,
      REACT_APP_MPNG_USER_API_BASE_URL: `${window.APP_ENV.userApiUrl}`
    },
    baseUrl: System.resolve("@wne/class-progress-report"),
    ilpsubject,
    ilpObservable,
    subjectMFE,
    observableMFE
  }
);

registerApplication(
  "@wne/mypath-assessment-reports",
  () => System.import("@wne/mypath-assessment-reports") as any,
  () => location.pathname.includes("/mypath-assessment-reports"),
  {
    domElement: document.getElementById("mypath-reports"),
    authTokenSubjectObservable,
    MyPathLevelSubject,
    MyPathLevelSubjectObservable,
    MyPathBreadcrumbsSubject,
    MyPathReassignSubject,
    MyPathReassignButtonObservable,
    ActivityIndicatorSubject,
    subjectMFE,
    observableMFE,
    MyPathLoaderSubject,
    mypathReportActiveFlagsSubject,
    sentryEnv: `${window.APP_ENV.sentry_env}`,
    productCode,
    env: {
      REACT_APP_FLAGR_API_BASE_URL: `${window.APP_ENV.lmsFlagrUrl}`
    }
  }
);

registerApplication(
  "@wne/benchmark-growth-reports",
  () => System.import("@wne/benchmark-growth-reports") as any,
  () => location.pathname.includes("/benchmark-growth-reports"),
  {
    domElement: document.getElementById("growth-reports"),
    tokenSubject: authTokenSubject,
    authTokenSubjectObservable,
    BenchmarkGrowthReportBreadcrumbSubject,
    BenchmarkGrowthReportBreadcrumbSubjectObservable,
    BenchmarkGrowthReportBackSubject,
    BenchmarkGrowthReportBackObservable,
    subjectMFE,
    observableMFE
  }
);

registerApplication(
  "@mps/standards-proficiency-report-mf",
  () => System.import("@mps/standards-proficiency-report-mf") as any,
  () => location.pathname.includes("/cumulativestandardsproficiency"),
  {
    apiUrls: {
      RAD_FASTLANE_API_URL: `${window.APP_ENV.reports_graphql_url}`,
      ASSIGNMENT_BUILDER_API_URL: `${window.APP_ENV.assignment_builder_url}`,
      REACT_APP_FLAGR_API_BASE_URL: `${window.APP_ENV.lmsFlagrUrl}`
    },
    domElement: document.getElementById("standardsproficiencyreport"),
    imlpTokenSubjectObservable: authTokenSubjectObservable,
    standardsProficiencyReportBreadcrumbsSubject,
    requestedFeatures: ["VIEW_COMPLETE_STUDENT_PROGRESS"],
    productBehaviorSubject: mpngStudentIlpSubject,
    sentryEnv: `${window.APP_ENV.sentry_env}`,
    productCode
  }
);

registerApplication(
  "@wne/assignment-builder-ui",
  () => System.import("@wne/assignment-builder-ui") as any,
  () => location.pathname.includes("/assignmentbuilder"),
  {
    domElement: document.getElementById("assignment-builder"),
    env: {
      REACT_APP_ASSIGNMENT_BUILDER_API_BASE_URL: `${window.APP_ENV.assignment_builder_url}`,
      REACT_APP_MPNG_API_BASE_URL: `${window.APP_ENV.mpng_api_base_url}`,
      REACT_APP_GRAPHQL_API_URL: `${window.APP_ENV.graphql_api_url}`,
      REACT_APP_FLAGR_BASE_URL_ASSIGNMENT_BUILDER: `${window.APP_ENV.flagr_base_url_assignment_builder}`,
      REACT_APP_DOOLITTLE_API_BASE_URL: `${window.APP_ENV.doolittle_api_base_url}`,
      REACT_APP_DOOLITTLE_READER_BASE_URL: `${window.APP_ENV.doolittle_reader_base_url}`,
      REACT_APP_FIXEDFORM_PLAYER_URL: `${window.APP_ENV.assessment_player_url}`,
      REACT_APP_FIXEDFORM_PLAYER_CTK_ENV_URL: `${window.APP_ENV.assessment_player_ctk_env_url}`,
      REACT_APP_FIXEDFORM_PLAYER_CTK_ENV_ID: `${window.APP_ENV.assessment_player_ctk_env_id}`,
      REACT_APP_ITEM_ENGINE_AUTHOR_URI: `${window.APP_ENV.itemEngineAuthorUri}`,
      REACT_APP_ITEM_ENGINE_ITEMS_URI: `${window.APP_ENV.itemEngineItemsUri}`
    },
    baseUrl: System.resolve("@wne/assignment-builder-ui"),
    tokenSubject: authTokenSubject,
    assignmentBuilderBreadcrumbSubject,
    assignmentBuilderBreadcrumbObservable,
    assignmentBuilderCustomPropsSubject,
    assignmentBuilderCustomPropsSubjectObservable,
    subjectMFE,
    observableMFE,
    productCode
  }
);

registerApplication(
  "@wne/class-summary-report",
  () => System.import("@wne/class-summary-report") as any,
  () => location.pathname.includes("/classsummary"),
  {
    domElement: document.getElementById("classsummary"),
    envJson: {
      REACT_APP_RAD_FASTLANE_API_URL: `${window.APP_ENV.reports_graphql_url}`,
      REACT_APP_MPNG_API_URL: `${window.APP_ENV.mpng_api_base_url}`,
      REACT_APP_MPNG_USER_API_BASE_URL: `${window.APP_ENV.userApiUrl}`,

      REACT_APP_DOOLITTLE_API_BASE_URL: `${window.APP_ENV.doolittle_api_base_url}`,

      REACT_APP_DOOLITTLE_READER_BASE_URL: `${window.APP_ENV.doolittle_reader_base_url}`,

      REACT_APP_RAD_FLAGR_BASE_URL: `${window.APP_ENV.flagr_base_url}`,

      REACT_APP_SENTRY_ENV: `${window.APP_ENV.sentry_env}`,
      REACT_APP_STUDENT_ILP_SENTRY_DSN: `${window.APP_ENV.student_ilp_sentry_dsn}`,
      REACT_APP_CLASS_SUMMARY_SENTRY_DSN: `${window.APP_ENV.class_summary_sentry_dsn}`,
      REACT_APP_ITEM_ENGINE_AUTHOR_URI: `${window.APP_ENV.itemEngineAuthorUri}`,
      REACT_APP_ITEM_ENGINE_ITEMS_URI: `${window.APP_ENV.itemEngineItemsUri}`
    },
    baseUrl: System.resolve("@wne/class-summary-report"),
    authTokenSubjectObservable,
    mpngClassSummarySubject,
    mpngClassSummaryObservable,
    mpngStudentIlpSubject,
    mpngStudentIlpObservable,
    subjectMFE,
    observableMFE
  }
);

registerApplication(
  "@wne/mpng-student-progress",
  () => System.import("@wne/mpng-student-progress") as any,
  () => location.pathname.includes("/studentprogress"),
  {
    domElement: document.getElementById("studentprogress"),
    envJson: {
      REACT_APP_RAD_FASTLANE_API_URL: `${window.APP_ENV.reports_graphql_url}`,
      REACT_APP_MPNG_API_URL: `${window.APP_ENV.mpng_api_base_url}`,
      REACT_APP_MPNG_USER_API_BASE_URL: `${window.APP_ENV.userApiUrl}`,

      REACT_APP_DOOLITTLE_API_BASE_URL: `${window.APP_ENV.doolittle_api_base_url}`,

      REACT_APP_DOOLITTLE_READER_BASE_URL: `${window.APP_ENV.doolittle_reader_base_url}`,

      REACT_APP_RAD_FLAGR_BASE_URL: `${window.APP_ENV.flagr_base_url}`,

      REACT_APP_SENTRY_ENV: `${window.APP_ENV.sentry_env}`,
      REACT_APP_STUDENT_ILP_SENTRY_DSN: `${window.APP_ENV.student_ilp_sentry_dsn}`,
      REACT_APP_CLASS_SUMMARY_SENTRY_DSN: `${window.APP_ENV.class_summary_sentry_dsn}`,
      REACT_APP_ITEM_ENGINE_AUTHOR_URI: `${window.APP_ENV.itemEngineAuthorUri}`,
      REACT_APP_ITEM_ENGINE_ITEMS_URI: `${window.APP_ENV.itemEngineItemsUri}`
    },

    baseUrl: System.resolve("@wne/mpng-student-progress"),
    authTokenSubjectObservable,
    mpngClassSummarySubject,
    mpngStudentIlpSubject,
    studentProgressSubject,
    observableMFE
  }
);

registerApplication(
  "@wne/decision-support-data-system-mf",
  () => System.import("@wne/decision-support-data-system-mf") as any,
  () => location.pathname.includes("/dsdsreport"),
  {
    domElement: document.getElementById("dsdsreport"),
    envjson: {
      REACT_APP_DSDS_APP_ENV_URL: `${window.APP_ENV.dsds_report_url}`
    },

    baseUrl: System.resolve("@wne/decision-support-data-system-mf"),
    authTokenSubjectObservable,
    dsdsReportSubject,
    dsdsReportObservable,
    subjectMFE,
    observableMFE
  }
);

registerApplication(
  "@mps/domain-mapping-mf",
  () => System.import("@mps/domain-mapping-mf") as any,
  () => location.pathname.includes("/domainmapping"),
  {
    domElement: document.getElementById("domainmapping"),
    envjson: {
      REACT_APP_DOMAIN_MAPPING_API_BASE_URL: `${window.APP_ENV.domain_mapping_api_base_url}`
    },
    baseUrl: System.resolve("@mps/domain-mapping-mf"),
    authTokenSubjectObservable,
    subjectMFE,
    observableMFE,
    domainMappingSubject,
    domainMappingObservable
  }
);

start();
